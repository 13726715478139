import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import RequestContent from './RequestContent';
import { ReactComponent as Delete } from '../../asset/icons/CloseButton.svg';
import { ReactComponent as Pending } from '../../asset/icons/Pending.svg';
import axios from 'axios';
import Loader from '../../components/Loader';

function RequestModal({
    isModalOpen,
    setIsModalOpen,
    endpoint,
    name,
    rejectConfigBody,
    approveConfigBody,
    fetchUtils,
    tableRowContent = {},
    isJobRequest,
}) {
    const [memberProfile, setMemberProfile] = useState([]);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('error');
    const [notification, setNotification] = useState(null);
    const [reloadToken, setReloadToken] = useState(false);

    // const reFetch = () => {
    //     setReloadToken((token) => !token);
    // };
    const showAlert = (type, message) => {
        setType(type);
        setNotification(message);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const onAction = () => {
        setLoading(true);
        axios
            .post(endpoint, approveConfigBody)
            .then((res) => {
                showAlert('success', 'The request has been approved');
                setIsModalOpen(false);
                fetchUtils.reFetch();
            })
            .catch((err) => {
                setLoading(false);
                fetchUtils.reFetch();
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const onReject = () => {
        setLoading(true);
        axios
            .post(endpoint, rejectConfigBody)
            .then((res) => {
                showAlert('success', 'The request has been rejected');
                closeModal();
                fetchUtils.reFetch();

                // reFetch();
            })
            .catch((err) => {
                setLoading(false);
                fetchUtils.reFetch();
                console.log(err, 'err');
            });
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div>
            <Modal
                centered
                show={isModalOpen}
                onHide={() => {
                    closeModal();
                }}
                backdrop={true}
            >
                <div style={{ padding: '3%' }}>
                    <div
                        style={{
                            display: 'flex',
                            borderBottom: '1px solid rgba(19, 24, 38, 0.05)',
                            paddingBottom: '3%',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '50%',
                            }}
                        >
                            <div
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '1%',
                                }}
                            >
                                <Pending />
                            </div>{' '}
                            {name}
                        </div>
                        <div
                            style={{
                                marginLeft: 'auto',
                                color: 'black',
                            }}
                            onClick={() => closeModal()}
                        >
                            <Delete />
                        </div>
                    </div>
                    <RequestContent
                        data={tableRowContent}
                        isJobRequest={isJobRequest}
                    />
                </div>
                <div
                    className='text-center'
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingBottom: '3%',
                    }}
                >
                    {console.log(tableRowContent, 'data help')}
                    <Button
                        className='mx-2 text-white px-5'
                        style={{
                            backgroundColor: '#386FFA',
                            borderRadius: '40px',
                            width: '45%',
                        }}
                        // disabled={loading}
                        onClick={onAction}
                    >
                        Approve
                    </Button>
                    <Button
                        className='mx-2 text-[#161619]  px-5'
                        style={{
                            backgroundColor: '#FFFFFF',
                            border: '1px solid #D8DEE4',
                            borderRadius: '40px',
                            width: '45%',
                        }}
                        // disabled={loading}
                        onClick={onReject}
                    >
                        Reject
                    </Button>
                </div>
            </Modal>
        </div>
    );
}

export default RequestModal;
